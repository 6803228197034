<template>
  <v-card
    class="text-center"
  >
    <v-card-title> {{ $t('ScanQR') }} </v-card-title>
    <v-card-text>
      <QRCode
        v-model="dataQr"
        class="qrcode"
      />
    </v-card-text>
    <v-btn
      class="mt-4 mb-8"
      color="warning"
      @click="$router.back()"
    >
      {{ $t('Back') }}
    </v-btn>
    <v-dialog
      v-model="isDialogVisible"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('Confirm') }}
        </v-card-title>
        <v-card-text>{{ $t('CheckInConfirm') }}: <strong>{{ dataQrComp.phone }}</strong> </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                color="error"
                outlined
                block
                @click="handleCloseDialog()"
              >
                {{ $t('No') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                block
                :loading="getLoadingCheckIn"
                @click="handleCheckin"
              >
                {{ $t('Yes') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import QRCode from '@/components/QRCode.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { QRCode },
  data() {
    return {
      dataQr: '',
      isDialogVisible: false,
    }
  },

  computed: {
    ...mapGetters('town', ['getLoadingCheckIn']),
    dataQrComp() {
      try {
        const parseValue = JSON.parse(this.dataQr)

        return parseValue
      } catch (error) {
        return {}
      }
    },
    checkInParams() {
      const { id, phone } = this.dataQrComp

      return `${id}-${phone}`
    },
  },

  watch: {
    dataQr(val) {
      if (val) {
        this.isDialogVisible = true
      }
    },
  },
  methods: {
    ...mapActions('town', ['checkIn']),
    async handleCheckin() {
      this.checkIn(this.checkInParams).finally(() => {
        this.handleCloseDialog()
      })
    },
    handleCloseDialog() {
      this.isDialogVisible = false
      this.dataQr = ''
      this.$router.push('/staff/town-infirmary')
    },
  },

  // setup() {
  //   const data = ref('')
  //   const isDialogVisible = ref(false)

  //   watch(data, val => {
  //     if (val) {
  //       isDialogVisible.value = true
  //     }
  //   })

  //   return {
  //     data,
  //     isDialogVisible,
  //   }
  // },
}
</script>
<style lang="scss" scoped>
.qrcode {
  border: 2px solid var(--v-primary-base);
  padding: 2px;
}
</style>
